.error-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f8f9fa;
  color: #333;
}

.error-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fff;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  max-width: 800px;
  width: 100%;
}

.error-text {
  flex-grow: 1; 
  padding-right: 1rem;
}

.error-title {
  font-size: 48px;
  font-weight: bold;
  margin-bottom: 16px;
  color: blueviolet;
}

.error-message {
  font-size: 48px;
  font-weight: 500;
  margin-bottom: 16px;
}

.error-description {
  font-size: 18px;
  margin-bottom: 16px;
  color: #74788d;
}

.error-details {
  font-size: 14px;
  color: #999;
  margin-bottom: 32px;
  display: flex;
  flex-direction: row;
}

.details-row {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-right: 25px;

}

.details-label {
  font-weight: bold;
  margin-bottom: 0;
}

.error-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 2rem;
}
