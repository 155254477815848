.custom-select__container {
  .ant-select-selector {
    border-radius: 2rem !important;
    span {
      margin-left: 17px !important;
    }
  }

  .ant-select {
    width: inherit;
  }
}
