.add-parameter-view-info {
    cursor: pointer;
    text-align: center;
    text-decoration-line: underline;
    color: #22a2ca;
}

.ant-pagination-item-active {
    background-color: #ccf1ff !important;
    color: #434b56 !important;
}

.ant-pagination-item {
    border: 1px solid #d2d8de !important;
    color: #434b56 !important;
    font-weight: bold;
}

.ant-pagination-prev {
    border: 1px solid #d2d8de !important;
}

.ant-pagination-next {
    border: 1px solid #d2d8de !important;
}

.ant-pagination-item-ellipsis{
    border: 1px solid #d2d8de !important;
    border-radius: 6px;
}

.ant-switch-checked {
    background: #44B6DC !important
}
