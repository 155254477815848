$rem-base: 0.5rem;
.flex {
  display: flex !important;
  &.column {
    flex-direction: column !important;
  }
}
.grid {
  display: grid !important;
}

@for $i from 1 through 12 {
  .cols-#{$i} {
    grid-template-columns: repeat($i, 1fr) !important;
  }
}

@for $i from 1 through 12 {
  .rows-#{$i} {
    grid-template-rows: repeat($i, 1fr) !important;
  }
}

@for $i from 1 through 12 {
  .cols-gap-#{$i} {
    column-gap: $i * $rem-base !important;
  }
}

.justify-between {
  justify-content: space-between !important;
}
.justify-center {
  justify-content: center !important;
}
.justify-between {
  justify-content: space-between !important;
}
.self-center {
  align-self: center !important;
}
.items-center {
  align-items: center !important;
}

.relative {
  position: relative !important;
}
.absolute {
  position: absolute !important;
}

@for $i from 1 through 10 {
  .mr-#{$i} {
    margin-right: $rem-base * $i !important;
  }
  .ml-#{$i} {
    margin-left: $rem-base * $i !important;
  }
  .mt-#{$i} {
    margin-top: $rem-base * $i !important;
  }
  .mb-#{$i} {
    margin-bottom: $rem-base * $i !important;
  }
  .m-#{$i} {
    margin: $rem-base * $i !important;
  }
}

.mr-auto {
  margin-right: auto !important;
}

.ml-auto {
  margin-left: auto !important;
}

@for $i from 1 through 10 {
  .pr-#{$i} {
    padding-right: $rem-base * $i !important;
  }
  .pl-#{$i} {
    padding-left: $rem-base * $i !important;
  }
  .pt-#{$i} {
    padding-top: $rem-base * $i !important;
  }
  .pb-#{$i} {
    padding-bottom: $rem-base * $i !important;
  }
  .p-#{$i} {
    padding: $rem-base * $i !important;
  }
}

.hide {
  display: none !important;
}
