.card-calibration-display__container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  column-gap: 3rem;
  row-gap: 2rem;
  .card-calibration-content__container {
    padding: 20px;
    display: grid;
    grid-template-columns: 50% 50%;
    row-gap: 1rem;
  }
}

.ant-badge-status-dot {
  width: 20px !important;
  height: 20px !important;
}

.modal-selfcalibration-create {
  right: 0px;
  top: -33px;
  position: absolute;
  height: 100vh;
  display: block;
  .rs-modal-dialog {
    height: -webkit-fill-available;
    padding: 0px !important;
    .rs-modal-content {
      height: inherit;
    }
  }
}


.form-configuration-container {
  display: grid;
  column-gap: 10px;
  grid-template-columns: 1fr 1fr 1fr;
}