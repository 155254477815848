.mro-collapse {
  .ant-collapse-content-box {
    background-color: white;
  }
  .ant-collapse-item {
    margin-bottom: 10px;
  }
}
.ant-table-wrapper{
  height: -webkit-fill-available;
}
