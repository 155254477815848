.tab {
    color: #023e65;
    font-style: normal;
    font-weight: 600;
    // font-size: 12px;
    line-height: 14px;
    display: flex;
    align-items: center;
    text-transform: uppercase;
}

.ant-collapse-header {
    background-color: white;
}

.ant-collapse {
    border: 0px solid #fff;
}

.ant-collapse-content-box {
    background: #f7f9fc;
}
.close {
    position: fixed;
    top: 75px;
    left: 279px;
    background: white;
    width: 40px;
    text-align: center;
    height: 38px;
    padding-top: 8px;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
}

.warning {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 4px 10px;
    gap: 10px;
    width: 90%;
    background: #ffeecb;
    border: 1px solid #e3a63d;
    border-radius: 8px;
    font-size: 12px;
}

.btn-kpi {
    box-sizing: border-box;
    color: #023e65;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
    background: #ffffff;
    border: 1px solid #023e65;
    border-radius: 52px;
    cursor: pointer;

    &:hover {
        color: #fff !important;
        background-color: #023e65;
    }
}

.title-kpi {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    display: flex;
    align-items: center;
    color: #434b56;
}

.footer-kpi {
    font-style: normal;
    font-weight: 400;
    font-size: 9px;
    line-height: 11px;
    color: #74788d;
    flex: none;
    order: 1;
    flex-grow: 0;
    margin-top: 7px;
}

.footer-date{
    margin-left: 3px;
}

.body-number-kpi {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 23px;
    /* identical to box height */

    display: flex;
    align-items: center;

    /* Blue Dark 2 */

    color: #023e65;
}

.body-unit-kpi {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    display: flex;
    align-items: center;
    color: #434b56;
    margin-top: 3px;
    margin-left: 5px;
}

// .ant-row{
//     height: 60px;
//     margin-top: -9px;
// }

.ant-card .ant-card-body {
    padding: 0px;
}

.left-kpi{
    padding-left: 13px;
    padding-top: 0px;
    width: 115px;
}

.right-kpi{
    // padding-left: 13px;
    // padding-top: 6px;
    width: 90px;
    flex: 0;
}
.parameter-name {
  display: inline-block;
  max-width: 180px; 
  overflow: hidden;
  word-wrap: break-word;
  white-space: normal;
  text-overflow: ellipsis;
}

  