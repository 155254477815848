.GeneralLayout {
  width: 100vw;
  height: 100vh;
  display: flex;
}
.sideBarContainer {
  background-color: #f7f9fc;
  width: 215px;
  padding: 20px;
  display: flex;
  flex-direction: column;
}

.mainContainer {
  display: flex;
  flex-direction: column;
  width: calc(100vw - 215px);
}

.sidebar-configuration {
  flex: 0 0 228px !important;
  max-width: 228px !important;
  min-width: 200px;
  width: 228px !important;
}
