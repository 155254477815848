@import "./colors.scss";
@import "./layout.scss";
@import "./inputs.scss";

.text-title-page {
  color: $blue-dark-2;
  font-size: 1.8rem;
  font-weight: bold;
}
.text-subtitle-page {
  font-size: 1rem;
  font-weight: 400;
}
.text-center {
  text-align: center !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

.w-full {
  width: 100% !important;
}

.filter-to-blue {
  filter: brightness(0) saturate(100%) invert(32%) sepia(99%) saturate(985%)
    hue-rotate(162deg) brightness(101%) contrast(94%);
}

.separator {
  height: 2px;
  background-color: #e6e6e6;
  width: 100%;
}
