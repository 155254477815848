.modal-alert {
  max-width: 300px;
  .rs-modal-dialog {
    max-width: 300px;
    .rs-modal-content .rs-modal-header {
      text-align: center;
      padding: 0px;
      border-bottom: solid 1px #c3c3c3;
      padding-bottom: 10px;
      .rs-modal-title {
        font-weight: bold;
        font-size: 16px;
        color: #1e1e1e;
        margin-top: 10px;
      }
    }
  }
}
