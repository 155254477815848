.body-popup {
  font-size: 10px !important;
  font-weight: 100 !important;
}

.popupTitle {
  display: flex;
  color: #023e65;
  text-transform: uppercase;
  font-weight: 600;
}
