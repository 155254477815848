.mapboxgl-popup-anchor-bottom .mapboxgl-popup-tip {
  border-top-color: rgb(41, 50, 60) !important;
}
.mapboxgl-popup-anchor-top .mapboxgl-popup-tip {
  border-bottom-color: rgb(41, 50, 60) !important;
}


.MapContainer {
  height: calc(100vh - 80px);
  width: 100%;
  position: relative;
  top: 80px;
  left: 0px;
}