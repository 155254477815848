.dashboard {
  display: flex;
  height: 100%;
}

.column-dashboard {
  flex: 1;
  border: 1px solid #ccc;
  padding: 10px;
  box-sizing: border-box;
  overflow: auto;
}

.column-real-losses {
  flex: 0 0 45%;
}

.full-height {
  height: 100%;
}

.half-height {
  height: 50%;
}

.quarter-height {
  height: 25%;
}

.three-quarters-height {
  height: 75%;
}

.section-dashboard {
  background-color: #f0f0f0;
  padding: 10px;
  border: 1px solid #ccc;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  .title {
    font-size: 1rem;
    font-weight: 600;
    // color: black;
  }

  .value {
    margin-top: 20px;
    font-size: 1.4rem;
    font-weight: 800;
    color: black;
  }

  .unit {
    font-size: 1rem;
    color: black;
  }
}

.half-width {
  width: 50%;
}

.double-section {
  padding: 0;
  display: flex;
  flex-direction: row;
}

.column-body-left {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100%;
  border-right: 1px solid #ccc;
}

.top {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-bottom: 1px solid #ccc;
}

.column-body {
  display: flex;
  flex-direction: column !important;
  height: 100%;
  span{
    margin-top: 0 !important;
  }
}

.bottom {
  display: flex;
  align-items: center;
  justify-content: center;
}
.t-l{
  background-color: white
}
.t-b{
  background-color: #EFFFFF
}
.low{
  font-size: 1rem !important;
}