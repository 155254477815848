.rs-tree-node-label {
  width: -webkit-fill-available !important;
}
.rs-tree-node-label-content {
  width: -webkit-fill-available !important;
}

.map-container {
  height: calc(100vh - 190px);
  border-radius: 2rem;
}

.mapboxgl-map {
  border-radius: 1rem !important;
}
