.react-flow__node-parameter {
  background: transparent !important;
}

.parameter-node {
  display: flex;
  flex-direction: column;
  background-color: white;
  border: solid 1px;
  border-radius: 8px;
}

.content-container {
  border-radius: 8px;
  background-color: white;
  width: 240px;
}

.btn-setParameter {
  background-color: #023e65;
  color: #fff;
  border: none;
  border-radius: 5px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  font-size: 12px;
  padding: 5px 8px;
  &:hover {
    background-color: #00203f;
  }
}
