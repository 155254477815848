.ant-menu.ant-menu-root {
  .ant-menu-item {
    margin-bottom: 1rem;
    &.ant-menu-item-selected {
      background-color: #f0f3f5;
      span.anticon {
        filter: brightness(0) saturate(100%) invert(32%) sepia(99%)
          saturate(985%) hue-rotate(162deg) brightness(101%) contrast(94%);
      }
      span.ant-menu-title-content {
        color: black;
        font-weight: bold;
      }
    }
  }
}
