.titulo-tabs {
    color: #023e65;
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    padding-left: 10px;
}

.contenedor-inicio {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 80vh;
    flex-direction: column;
}

.texto-inicio {
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
}

.boton-inicio {
    height: 40px;
    border: 1px solid #023e65;
    border-radius: 52px;
    background: #023e65;
    color: white;
}

.boton-inicio:hover {
    background: #023e65;
    color: white !important;
}

.boton-regreso {
    width: 150px;
    height: 20px;
    padding-top: 2px;
    padding-left: 20px;
    background: #f7f9fa;
    border-radius: 20px;
    font-weight: 400;
    font-size: 10px;
}

.table-data-gis {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    color: #232b36;
}

.date-gis {
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    color: #74788d;
}
