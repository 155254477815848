.option-graph {
  color: #023e65;
  text-decoration: underline;
  font-weight: bold;
  cursor: pointer;
}

.hidden {
  display: none;
}
.option-color {
  background-color: white;
}

.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 1;
}