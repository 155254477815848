.language-btn {
  background: none;
  border: none;
  cursor: pointer;
}

.marker-btn {
  background: none;
  border: none;
  cursor: pointer;
}

.marker-btn img {
  width: 40px;
}

.popup {
  width: 500px;
}

.popup h5 {
  text-align: center;
  color: #5e738b;
  font-weight: bolder;
}

.popup p {
  text-align: justify;
}

.popup span {
  color: #078cb3;
  text-align: right;
}

.popupBody {
  margin-top: 1rem !important;
}

.popupTarget p {
  font-size: 3rem;
}

.popupTarget span {
  font-size: 1rem;
  display: flex;
  justify-content: flex-end;
  color: #078cb3;
}

.mapboxgl-popup-content {
  font: 400 15px/22px "Source Sans Pro", "Helvetica Neue", sans-serif;
  padding: 0;
  /* width: 540px; */
}

.listings {
  height: 49vh;
  overflow-y: scroll;
  overflow-x: hidden;
  padding-bottom: 60px;
  padding-top: 1.8rem;
}

::-webkit-scrollbar {
  width: 3px;
  height: 3px;
  border-left: 0;
  background: rgba(0, 0, 0, 0.1);
}

::-webkit-scrollbar-track {
  background: none;
}

::-webkit-scrollbar-thumb {
  background: #078cb3;
  border-radius: 0;
}

.activities-hide {
  position: fixed;
  width: 300px !important;
  display: grid;
  right: 1px;
  top: 75px;
  background-color: #f2f4f4;
  height: 5%;
  margin-right: 2rem;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.08), 0 0 6px rgba(0, 0, 0, 0.05);
}

.activities {
  cursor: auto;
  position: fixed;
  width: 300px !important;
  display: grid;
  right: 1px;
  top: 75px;
  background-color: #f2f4f4;
  height: 70%;
  margin-right: 2rem;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.08), 0 0 6px rgba(0, 0, 0, 0.05);
}

.activities-today {
  font-weight: 600;
  margin-bottom: 0.5rem;
  margin-left: 0.5rem;
}

.heading-hide {
  height: fit-content;
}

.heading-hide h5 {
  color: #475f7b;
  text-align: center;
  margin-left: 3rem;
}
.heading {
  height: fit-content;
  border-bottom: 2px solid #078cb3;
}

.heading h5 {
  color: #475f7b;
  text-align: center;
  margin-left: 3rem;
}

.card-container {
  margin: -1.6rem 18px 2rem 18px;
}

.card {
  border-radius: 0px 4px 4px 0px !important;
  background: #fff;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.08), 0 0 6px rgba(0, 0, 0, 0.05);
  transition: 0.3s transform cubic-bezier(0.155, 1.105, 0.295, 1.12),
    0.3s box-shadow,
    0.3s -webkit-transform cubic-bezier(0.155, 1.105, 0.295, 1.12);
  padding: 9px 25px 18px 5px;
  cursor: pointer;
  height: 65px;
}

.card-title {
  margin-left: -1rem;
  margin-bottom: 0.1rem !important;
}

.card-status {
  color: gray;
  font-size: 0.8rem;
  margin-left: -15.7rem;
  padding-top: 1.3rem;
}

.kpis {
  position: fixed;
  width: 59% !important;
  display: flex;
  left: 18rem;
  top: 75px;
  z-index: 2;
}

.kpi {
  cursor: auto;
  background-color: #fff;
  margin-left: 1rem;
  border-radius: 4px;
  width: inherit;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.08), 0 0 6px rgba(0, 0, 0, 0.05);
  height: 130px;
}

.kpi-content {
  margin: 1rem 2rem 0rem 1rem;
  border-bottom: 1px solid #078cb3;
}

.kpi-title {
  color: #475f7b;
  font-weight: 600;
  text-align: center;
}

.kpi-content-2 {
  margin: 0rem 2rem 0.5rem 1rem;
  display: flex;
  align-items: center;
}

.kpi-count {
  font-size: 1.5rem;
  color: #475f7b;
  font-weight: 600;
  margin-left: 2rem;
  margin-top: 0.3rem !important;
}

.kpi-target {
  font-size: 0.8rem;
  color: #078cb3;
  margin-left: 1.5rem;
  margin-top: 0.6rem !important;
}

.kpi-graph {
  width: 40%;
}

.robot {
  background-color: #313b3e;
  width: 45px;
  padding: 1rem 0.5rem 1rem 0.5rem;
  border-radius: 0rem 1rem 1rem 0rem;
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}

.viewMore {
  background-color: #f2f4f4;
  padding: 0.5rem 1rem 0.01rem 1rem;
  box-shadow: 0 2px 10px rgb(0 0 0 / 8%), 0 0 6px rgb(0 0 0 / 5%);
  width: fit-content;
  border-radius: 4px;
}

.viewMoreTitle {
  border-bottom: 1px solid #078cb3;
  padding: 6px;
  text-align: center;
  font-weight: 700;
  font-size: 16px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}
.viewContent {
}

.table-borderless thead th {
  color: #92b0b8;
  font-size: 10px;
}

.table-borderless tbody td {
  color: #42555a;
  font-weight: 600;
  font-size: 10px;
}

.mapboxgl-popup-close-button {
  display: none;
}

.mapboxgl-popup-anchor-bottom .mapboxgl-popup-tip {
  align-self: center;
  border-bottom: none;
  border-top-color: #fff;
  padding-top: 1rem;
}

.spinner {
  padding: 16% 0% 0% 40%;
}

.spinner-border {
  width: 19rem !important;
  height: 19rem !important;
  vertical-align: -0.125em !important;
  border: 1.25em solid currentColor !important;
  border-right-color: transparent !important;
}

.spinner-text {
  position: absolute;
  top: 24.4rem;
  left: 46rem;
  color: #0dcaf0;
  font-size: larger;
}
