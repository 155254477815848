.dndflow .dndnode {
  height: 32px;
  width: 130px;
  padding: 4px;
  border: 1px solid #023e65;
  border-radius: 40px;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: grab;
  color: #00203c;
  font-weight: 500;
}

.dndflow .dndnode.input {
  border-color: #0041d0;
}

.dndflow .dndnode.output {
  border-color: #ff0072;
}

.dndflow aside {
  border-right: 1px solid #eee;
  padding: 15px 10px;
  font-size: 12px;
  background: #ffffff;
  margin: 10px;
  box-shadow: 0px 0px 12px rgba(0, 32, 60, 0.1);
  max-width: 420% !important;
  min-width: 420px !important;
}


.css-dev-only-do-not-override-pxijdi.ant-form-inline .ant-form-item {
  margin-inline-end: 0px;
  padding-right: 5px;
}