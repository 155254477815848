.source-value {
  font-weight: 700;
  word-break: break-all;
  white-space: normal;
  overflow: hidden;
  width: 100%;
}

.rs-modal-content {
  overflow-x: auto;
}

.rs-modal-wrapper:has(> .modal-virtual-source) {
  z-index: 999999;
}
