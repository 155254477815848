.modal-source-detail {
  right: 0px;
  top: -33px;
  position: absolute !important;
  height: 100vh;
  display: block;
  .rs-modal-dialog {
    height: -webkit-fill-available;
    padding: 0px !important;
    .rs-modal-content {
      height: inherit;
    }
  }
}

.card-source-container {
  .ant-card-body {
    padding: 0px 20px;
  }
}

.ant-tabs-nav,
.ant-modal-title {
  padding: 0px 20px;
}

.rs-list {
  width: -webkit-fill-available;
  position: relative !important;
  .rs-list-sorting {
    z-index: 99999999;
    .rs-list-item-holder {
      z-index: 99999999999999;
    }
  }
}

.position-circle {
  &:nth-last-child(2) {
    .position-circle-border {
      .arrow {
        display: none;
      }
    }
  }
  .position-circle-border {
    width: 40px;
    height: 40px;
    border: 1px dashed #00203c;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;

    .arrow {
      position: absolute;
      display: flex;
      top: 55px;
      width: 2px;
      height: 90px;
      background-color: #078cb3;
      &::after {
        content: "";
        width: 2px;
        height: 20px;
        transform: rotate(45deg);
        background-color: #078cb3;
        position: absolute;
        bottom: -2px;
        right: -8px;
      }
      &::before {
        content: "";
        width: 2px;
        height: 20px;
        transform: rotate(-45deg);
        background-color: #078cb3;
        position: absolute;
        bottom: -2px;
        right: 8px;
      }
    }
    .position-circle-content {
      width: 24px;
      height: 24px;
      background-color: #00203c;
      color: white;
      border-radius: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 700;
    }
  }
}
