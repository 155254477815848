.preview {
    padding-left: 120px; 
    width: 1680px;
}

@media only screen and (min-width: 1920px) {
    .preview {
        padding-left: 260px;    
        width: 100%;
    }
}

.table .thead-dark th {
    color: #fff;
    background-color: #078cb3;
    border-color: #078cb3 !important;
    font-size: 12px;
    vertical-align: middle
}

.table thead th {
    color: #fff;
    background-color: #078cb3;
    border-color: #078cb3;
}

.table tbody tr td {
    vertical-align: middle;
    font-size: 14px;
}

.table>:not(:first-child) {
    border-top: 2px solid #078cb3 !important;
}

.btn:hover {
    color: #fff !important;
}

.btn-hydro {
    color: #fff !important;
    background-color: #078cb3 !important;
    border-color: #078cb3 !important;
}

.span-circle-red{
    display: inline-block;
    margin-left: 0.5rem;
    margin-right: 4px; 
    border-radius: 10px;
    width: 10px;
    height: 10px;
    background-color: #FF0000
}

.span-circle-green{
    display: inline-block;
    margin-left: 0.5rem;
    margin-right: 4px; 
    border-radius: 10px;
    width: 10px;
    height: 10px;
    background-color: #ADFF2F
}
.span-circle-orange{
    display: inline-block;
    margin-left: 0.5rem;
    margin-right: 4px; 
    border-radius: 10px;
    width: 10px;
    height: 10px;
    background-color: #FFA500
}
