.add-files {
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
    color: #023e65;
    height: 40px;
    padding: 8px 16px;
    margin-top: 9px;
    background: #ffffff;
    border: 1px solid #023e65;
    border-radius: 52px;
}

.drag-drop {
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #74788d;
}

:where(.css-dev-only-do-not-override-183javq).ant-table-wrapper .ant-table-thead >tr>th, :where(.css-dev-only-do-not-override-183javq).ant-table-wrapper .ant-table-thead >tr>td {
    color: #434B56;
    background: #fff;
}

.map-container-gis{
    height: 100%;
    border-radius: 2rem;
}
