.ant-popover {
    padding-top: 28px;
}

.ant-picker-dropdown .ant-picker-panel-container .ant-picker-presets ul li {
    display: flex;
    flex-direction: row ;
    align-items: center;
    padding: 10px 12px;
    gap: 10px;
    color: #232b36;
}

.rcw-header span {
    display: none !important;
}
.rcw-conversation-container{
    max-width: 370px !important;
}
.rcw-conversation-container .rcw-header{
    background-color: rgba(0, 0, 0, 0.88) !important;
    
}
.rcw-header{
    font-size: 18px !important;
    margin: 0 !important;
    padding: 0 !important;
}
.rcw-message-text{
    line-height: 17px !important;
}
.rcw-sender {
    line-height: 44px !important;
}
.rcw-conversation-container{
    position: fixed !important;
    bottom: 50px !important;
    right: 20px !important;
}
.rcw-widget-container {
    display: block !important;
    position: relative !important;
    margin: 0 !important;
}

.rcw-close-button {
    border-radius: 22px !important;
    display: block !important;
    position: absolute !important;
    right: 7px !important;
    top: -7px !important;
    width: 40px !important;
    height: 0px;
    img{
        height: 15px;
    }
}