.ant-badge-status-dot {
  width: 10px !important;
  height: 10px !important;
}

.card-display__container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  column-gap: 3rem;
  row-gap: 2rem;
  .card-content__container {
    display: grid;
    grid-template-columns: 40% 10% 40% 10%;
  }
}
