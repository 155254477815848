.containerDiagramBlock {
  padding-top: 75px;
  height: 100vh;
}

.dndflow {
  flex-direction: column;
  display: flex;
  flex-grow: 1;
  height: 100%;
}


.dndflow aside .description {
  margin-bottom: 10px;
}

.dndflow .reactflow-wrapper {
  flex-grow: 1;
  height: 100%;
}

.dndflow .selectall {
  margin-top: 10px;
}

@media screen and (min-width: 768px) {
  .dndflow {
    flex-direction: row;
  }

  .dndflow aside {
    width: 20%;
    max-width: 250px;
  }
}
