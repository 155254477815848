.ant-select-selector.rounded {
  border-radius: 2rem !important;
}

.ant-input-affix-wrapper {
  border-radius: 2rem !important;
}

// .ant-btn {
//   border-radius: 2rem !important;
// }
