.title-modal {
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    color: #023E65;
    margin-left: -20px;
}

.item-label {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    display: flex;
    color: #434B56;
}

.accept-button {
    width: 85px;
    height: 40px !important;
    border: 1px solid #023e65;
    border-radius: 8px;
    background: #00203c;
    border: 1px solid #74788d;
    color: #ffffff;
}

.accept-button:hover {
    background: #00203c !important;
    color: #ffffff !important;
}

.cancel-button {
    width: 85px;
    height: 40px !important;
    background: #ffffff;
    border: 1px solid #74788d;
    border-radius: 8px;
    color: #74788d;
}

.cancel-button:hover {
    background: #ffffff !important;
    color: #74788d !important;
}
