.modalBackground {
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    z-index: 1029;
}
.modalContainer {
    width: 360px;
    height: 556px;
    border-radius: 12px;
    background-color: white;
    /* box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; */
    display: flex;
    flex-direction: column;
    padding: 4rem;
}

.modalContainer .title {
    display: inline-block;
    text-align: center;
    margin-top: 10px;
}

.titleCloseBtn {
    display: flex;
    justify-content: flex-end;
}

.titleCloseBtn button {
    background-color: transparent;
    border: none;
    font-size: 25px;
    cursor: pointer;
}

.modalContainer .body {
    flex: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    text-align: center;
    color: black;
    font-weight: 600;
}

.modalContainer .footer {
    flex: 20%;
    display: contents;
    justify-content: center;
    align-items: center;
}

.modalContainer .footer .no {
    width: 100%;
    margin: 10px;
    border: 2px solid #088cb3;
    background-color: #088cb3;
    color: white;
    border-radius: 2rem;
    font-size: 20px;
    cursor: pointer;
}

.modalContainer .footer .yes {
    width: 100%;
    margin: 10px;
    border: 2px solid #088cb3;
    background-color: #fff;
    color: #088cb3;
    border-radius: 2rem;
    font-size: 20px;
    cursor: pointer;
}

#cancelBtn {
    background-color: crimson;
}
